import { Button, ComponentWrapper, Table } from '@components/ui'
import { useWorkingHoursManagementFunctions } from '@hooks/api/working-hours-management/useWorkingHoursManagementFunctions'
import { useOrder } from '@hooks/useOrder'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { IWorkingHoursActiveTableData } from '../../../../types'
import { NotificationModal } from '../../../WorkingHours/Components/NotificationModal'
import { SendReminderRenderer } from '../Renderers/SendReminderRenderer'

export const NotSentWorkingHoursManagementTable = ({ data = [] }: { data: IWorkingHoursActiveTableData[] }) => {
  const [page, setPage] = useState(1)
  const [order, setOrder, orderData] = useOrder({ key: 'date', direction: 'DESC' })
  const { t } = useTranslation()
  const {
    sendWorkingHoursReminders: { mutateAsync: sendWorkingHoursReminders, isPending },
  } = useWorkingHoursManagementFunctions()
  const [isSendRemindersModalOpen, setIsSendRemindersModalOpen] = useState(false)

  const handleSendReminderToAll = useCallback(async () => {
    await sendWorkingHoursReminders({ data: { ids: (data || [])?.map(row => row.id.toString()) } })
    setIsSendRemindersModalOpen(false)
  }, [data, sendWorkingHoursReminders])

  return (
    <>
      <ComponentWrapper>
        <Table
          order={order}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            total: (data || []).length,
          }}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('working_hours_management.name'),
              order: setOrder,
              render: data => <>{data['name'] || '-'}</>,
            },
            {
              key: 'date',
              dataIndex: 'date',
              title: t('working_hours_management.month'),
              order: setOrder,
              decoration: tw`w-[100px]`,
              render: data => <>{dayjs(data['date']).format('MMMM')}</>,
            },
            {
              key: 'date_approved',
              dataIndex: 'date_approved',
              title: '',
              order: setOrder,
              decoration: tw`w-[50px]`,
              render: data => <SendReminderRenderer data={data} />,
            },
          ]}
          data={(data || []).map(d => ({ ...d, key: d.id })).sort(orderData)}
        />
      </ComponentWrapper>
      {data.length > 0 && (
        <div className={'flex w-full justify-end'}>
          <Button onClick={() => setIsSendRemindersModalOpen(true)} isLoading={isPending}>
            {t('working_hours_management.send_reminder_to_all')}
          </Button>
        </div>
      )}
      <NotificationModal
        key={'send_reminders'}
        isOpen={isSendRemindersModalOpen}
        onClose={() => setIsSendRemindersModalOpen(false)}
        title={'Pošalji podsjetnike'}
        subtitle={
          'Jeste li sigurni da želite poslati podsjetnik svima na listi. Potvrđivanjem ove radnje podsjetnici će biti poslani putem maila.'
        }
        primaryButtonText={'Pošalji'}
        secondaryButtonText={'Odustani'}
        isLoading={isPending}
        onPrimaryClick={handleSendReminderToAll}
        onSecondaryPress={() => setIsSendRemindersModalOpen(false)}
      />
    </>
  )
}
