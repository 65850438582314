import { deleteEmployee } from '@api/employee'
import { DepartmentDto } from '@api/generated-api'
import { ITableData, Table, Tag } from '@components/ui'
import { useEmployees } from '@hooks/api/employee'
import { useNavigation } from '@hooks/useNavigation'
import { formatCurrency } from '@utils/formatCurrency'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { EditDeleteRender } from '../../../components/Renders/EditDeleteRender'
import { NotificationModal } from '../../WorkingHours/Components/NotificationModal'
import { useOrder } from '@hooks/useOrder'

export const EmployeesList = () => {
  const { data, isLoading, refetch } = useEmployees()
  const [isDeleteOpen, setIsDeleteOpen] = useState<undefined | ITableData>(undefined)
  const [order, setOrder, orderData] = useOrder({ key: 'name', direction: 'ASC' })

  const [search, setSearch] = useState('')
  const { employee } = useNavigation()
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  const delEmployee = async () => {
    const data = await deleteEmployee(isDeleteOpen!.id as number)
    if (data.status < 300) {
      setIsDeleteOpen(undefined)
      refetch()
    }
  }

  const navigateToEmployee = (key: string | number) => {
    employee(key)
  }

  const parsedData = useMemo(() => (data?.data || []).map(val => ({ ...val, key: val.id })), [data?.data])

  const filteredData = (
    (parsedData || []).filter(v =>
      Object.values(v).reduce(
        (acc, v) => acc || (typeof v == 'string' && v.toLowerCase().includes(search.toLowerCase())),
        false,
      ),
    ) ?? []
  ).sort(orderData)

  return (
    <>
      <div tw='mb-10 overflow-scroll'>
        <Table
          onRowClick={navigateToEmployee}
          isLoading={isLoading}
          order={order}
          title={t('employees.title') as string}
          search={setSearch}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            perPage: 10,
            total: filteredData.length ?? 0,
          }}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('shared.employee'),
              sortableData: data => data?.name,
              order: setOrder,
            },
            {
              key: 'departments',
              dataIndex: 'departments',
              title: t('employees.departments'),
              render: data => (
                <>
                  {(data['departments'] ?? []).map((dep: DepartmentDto) => (
                    <Tag key={dep.id} title={dep.name}>
                      {dep.name}
                    </Tag>
                  ))}
                </>
              ),
              order: setOrder,
              sortableData: data => (data?.departments || []).map((dep: DepartmentDto) => dep.name).join('-'),
            },
            {
              key: 'neto',
              dataIndex: 'neto',
              title: t('employees.neto'),
              render: data => <>{formatCurrency(data?.salaries[0]?.neto)}</>,
              sortableData: data => data?.salaries[0]?.neto,
              order: setOrder,
            },
            {
              key: 'bruto2',
              dataIndex: 'bruto2',
              title: t('employees.bruto2'),
              render: data => <>{formatCurrency(data?.salaries[0]?.bruto2)}</>,
              sortableData: data => data?.salaries[0]?.bruto2,
              order: setOrder,
            },
            {
              key: 'total',
              dataIndex: 'total',
              title: t('employees.total_cost'),
              render: data => <>{formatCurrency(data?.salaries[0]?.total)}</>,
              sortableData: data => data?.salaries[0]?.total,
              order: setOrder,
            },
            {
              key: 'trash',
              dataIndex: 'trash',
              title: '',
              decoration: tw`w-[100px]`,
              render: data => <EditDeleteRender onDelete={() => setIsDeleteOpen(data)} />,
            },
          ]}
          data={filteredData}
        />
      </div>
      <NotificationModal
        isOpen={!!isDeleteOpen}
        onClose={() => setIsDeleteOpen(undefined)}
        title={'Obriši zaposlenika'}
        subtitle={'Potvrđivanjem radnje svi podaci vezani za ovog zaposlenika bit će trajno obrisani.'}
        onPrimaryClick={delEmployee}
        secondaryButtonText={t('shared.give_up') as string}
        primaryButtonText={t('shared.confirm') as string}
      />
    </>
  )
}
