import { ITableColumn } from '@components/ui'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { IImportChanges, IImportRow, ImportSelectState, ImportType } from 'src/types'
import tw from 'twin.macro'

const renderColumn = (data: any[], key: string, type: 'string' | 'number' | 'date' = 'string') => {
  const changedValue = (data.changed || []).find((c: { key: string }) => c.key === key)
  const isChanged = changedValue?.new === data?.[key]

  const parsedValue =
    data?.[key] === '-' || !data?.[key]
      ? '-'
      : type === 'number'
      ? Number(data?.[key])
      : type === 'date'
      ? dayjs(data?.[key]).format('DD.MM.YYYY')
      : data?.[key]

  return (
    <div
      className={'flex items-center justify-center rounded-sm px-1 py-0.5'}
      css={isChanged ? [tw`bg-emerald-300/20 font-semibold`] : []}
    >
      {['hoursType'].includes(key) ? t(`import.hours.${parsedValue.toLowerCase()}`) || '-' : parsedValue || '-'}
    </div>
  )
}

export const getImportColumnConfig = ({ type }: { type: ImportType }): ITableColumn[] => {
  switch (type) {
    case ImportType.CLIENTS:
      return [
        {
          key: 'name',
          dataIndex: 'name',
          decoration: tw`pl-6`,
          rowDecoration: tw`pl-6`,
          title: t('import.name'),
          render: data => renderColumn(data, 'name'),
        },
        {
          key: 'date',
          dataIndex: 'date',
          decoration: tw`pr-6`,
          rowDecoration: tw`pr-4`,
          title: t('import.date'),
          render: data => renderColumn(data, 'date', 'date'),
        },
      ]
    case ImportType.EMPLOYEES:
      return [
        {
          key: 'name',
          dataIndex: 'name',
          decoration: tw`pl-3`,
          title: t('import.name'),
          render: data => renderColumn(data, 'name'),
        },
        {
          key: 'email',
          dataIndex: 'email',
          title: t('import.email'),
          render: data => renderColumn(data, 'email'),
        },
        {
          key: 'username',
          dataIndex: 'username',
          title: t('import.username'),
          render: data => renderColumn(data, 'username'),
        },
        {
          key: 'department1',
          dataIndex: 'department1',
          title: t('import.department_one'),
          render: data => renderColumn(data, 'department1'),
        },
        {
          key: 'role1',
          decoration: tw`max-w-[100px]`,
          dataIndex: 'role1',
          title: t('import.role_one'),
          render: data => renderColumn(data, 'role1'),
        },
        {
          key: 'department2',
          dataIndex: 'department2',
          title: t('import.department_two'),
          render: data => renderColumn(data, 'department2'),
        },
        {
          key: 'role2',
          dataIndex: 'role2',
          decoration: tw`max-w-[100px]`,
          title: t('import.role_two'),
          render: data => renderColumn(data, 'role2'),
        },
        {
          key: 'arrival',
          dataIndex: 'arrival',
          title: t('import.arrival_date'),
          render: data => renderColumn(data, 'arrival', 'date'),
        },
        {
          key: 'departure',
          dataIndex: 'departure',
          title: t('import.departure_date'),
          render: data => renderColumn(data, 'departure', 'date'),
        },
        {
          key: 'hoursType',
          dataIndex: 'hoursType',
          title: t('import.work_type'),
          render: data => renderColumn(data, 'hoursType'),
        },
      ]
    case ImportType.SALARIES:
      return [
        {
          key: 'name',
          dataIndex: 'name',
          decoration: tw`pl-3`,
          title: t('import.name'),
          render: data => renderColumn(data, 'name'),
        },
        {
          key: 'date',
          dataIndex: 'date',
          title: t('import.date'),
          render: data => renderColumn(data, 'date', 'date'),
        },
        {
          key: 'bruto2',
          dataIndex: 'bruto2',
          title: t('import.bruto_2'),
          render: data => renderColumn(data, 'bruto2', 'number'),
        },
        {
          key: 'neto',
          dataIndex: 'neto',
          title: t('import.neto'),
          render: data => renderColumn(data, 'neto', 'number'),
        },
        {
          key: 'transport',
          dataIndex: 'transport',
          title: t('import.transport'),
          render: data => renderColumn(data, 'transport', 'number'),
        },
        {
          key: 'fuel',
          dataIndex: 'fuel',
          title: t('import.fuel'),
          render: data => renderColumn(data, 'fuel', 'number'),
        },
        {
          key: 'parking',
          dataIndex: 'parking',
          title: t('import.parking'),
          render: data => renderColumn(data, 'parking', 'number'),
        },
        {
          key: 'other',
          dataIndex: 'other',
          title: t('import.other'),
          render: data => renderColumn(data, 'other', 'number'),
        },
        {
          key: 'kind',
          dataIndex: 'kind',
          title: t('import.in_kind'),
          render: data => renderColumn(data, 'kind', 'number'),
        },
        {
          key: 'description',
          dataIndex: 'description',
          title: t('import.description'),
          render: data => renderColumn(data, 'description'),
        },
      ]
  }
}

const getImportRowData = ({ array, type }: { array: IImportRow<any>[]; type: ImportType }) => {
  switch (type) {
    case ImportType.CLIENTS:
      return array.map(row => {
        return {
          key: (row?.id || '').toString(),
          name: row?.name,
          date: row?.date,
          changed: row?.changed,
          status: row?.status,
          decoration: row?.status === 'added' ? tw`bg-[#DDFCF1B8]` : row?.status === 'deleted' ? tw`bg-red-100` : null,
        }
      })
    case ImportType.EMPLOYEES:
      return array.map(row => {
        return {
          name: row?.name,
          email: row?.email || '-',
          username: row?.username || '-',
          department1: row?.department1 || '-',
          role1: row?.role1 || '-',
          department2: row?.department2 || '-',
          role2: row?.role2 || '-',
          arrival: row?.arrival || '-',
          departure: row?.departure || '-',
          hoursType: row?.hoursType || '-',
          key: (row?.id || '').toString(),
          status: row?.status,
          decoration: row?.status === 'added' ? tw`bg-[#DDFCF1B8]` : row?.status === 'deleted' ? tw`bg-red-100` : null,
          changed: row?.changed,
        }
      })
    case ImportType.SALARIES:
      return array.map(row => {
        return {
          key: (row?.id || '').toString(),
          name: row?.name,
          email: row?.email || '-',
          date: row?.date || '-',
          bruto2: row?.bruto2 || '-',
          neto: row?.neto || '-',
          transport: row?.transport || '-',
          fuel: row?.fuel || '-',
          parking: row?.parking || '-',
          other: row?.other || '-',
          kind: row?.kind || '-',
          description: row?.description || '-',
          status: row?.status,
          decoration: row?.status === 'added' ? tw`bg-[#DDFCF1B8]` : row?.status === 'deleted' ? tw`bg-red-100` : null,
          changed: row?.changed,
        }
      })
    default:
      return []
  }
}

export const getImportTableData = (data: IImportChanges<any>, view: ImportSelectState, type: ImportType) => {
  const {
    all = [],
    toBeUpdated = [],
    toBeDeleted = [],
    toBeAdded = [],
  } = data ?? {
    all: [],
    toBeAdded: [],
    toBeDeleted: [],
    toBeUpdated: [],
  }
  console.log({ toBeUpdated })

  switch (view) {
    case ImportSelectState.ALL:
      return getImportRowData({ array: all, type }) ?? []
    case ImportSelectState.ADDED:
      return getImportRowData({ array: toBeAdded, type }) ?? []
    case ImportSelectState.CHANGED:
      return getImportRowData({ array: toBeUpdated, type }) ?? []
    case ImportSelectState.REMOVED:
      return getImportRowData({ array: toBeDeleted, type }) ?? []
  }
}
