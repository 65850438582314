import { UserImportChangesDto } from '@api/generated-api'
import { IApiError } from '@interfaces/apiError'
import { AxiosResponse } from 'axios'
import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import 'twin.macro'
import { IImportChanges, ImportType } from '../../../types'
import { ImportChangesDrawer } from '../ImportChangesDrawer/ImportChangesDrawer'

interface IImportInput {
  tempInputRef?: RefObject<HTMLInputElement>
  setInputRef: Dispatch<SetStateAction<RefObject<HTMLInputElement> | undefined>>
  type: ImportType
  onUploadTemplate: (file: File) => Promise<AxiosResponse<IImportChanges<any>, IApiError>>
  onUpload: (importChanges: UserImportChangesDto) => Promise<AxiosResponse<any, any>>
}

export const ImportInput = ({ setInputRef, tempInputRef, type, onUpload, onUploadTemplate }: IImportInput) => {
  const [importChanges, setImportChanges] = useState<IImportChanges<any> | undefined>(undefined)
  const [fileName, setFileName] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!!setInputRef && inputRef.current && !tempInputRef) {
      setInputRef(inputRef)
    }
  }, [setInputRef, tempInputRef])

  console.log('importChanges', importChanges)

  return (
    <>
      <input
        id={'template-upload'}
        type='file'
        className={'hidden'}
        ref={inputRef}
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        multiple={false}
        onChange={async e => {
          if (e?.target?.files?.[0]) {
            const file = e?.target?.files?.[0]
            setFileName(file.name)
            const newItems = await onUploadTemplate(file)
            console.log('newItems', newItems.data)
            if (newItems) {
              console.log('newItems', newItems)
              setImportChanges(newItems.data)
              if (inputRef?.current?.value) {
                inputRef.current.value = ''
              }
            }
          }
        }}
      />
      <ImportChangesDrawer
        onUpload={onUpload}
        type={type}
        setImportChanges={setImportChanges}
        importChanges={importChanges}
        fileName={fileName}
      />
    </>
  )
}
